<template>
  <!-- 基层治理 -->
  <div class="content-box">
    <div class="title-box">
      <div class="title-name">基层治理</div>
      <div class="title-p">
        “基层治理是对基层地区(如城市社区、农村等)的治理。
        基层是社会治理的基础,国家治理现代化离不开基层治理现代化。城市的社区治理、农村的村落治理,都属于基层治理范畴。”
      </div>
    </div>

    <div class="function">
      <div class="services">
        <div class="left"></div>
      </div>
    </div>
    <div class="function">
      <div class="function-title">功能场景</div>
    </div>
    <div class="scene">
      <h1></h1>
      <div class="img2" @click="go(1)">
        <div class="sceneBox">
          <div class="sceneIcon icon1"></div>
          <div class="sceneTitle">统一指挥中心</div>
          <div class="sceneContent">
            指挥中心依托网格化管理，构建全域覆盖的社会治理“一张网”，真正实现“全域联动、一网统管、一体指挥”，不断提升基层社会治理现代化水平
          </div>
        </div>
      </div>
      <div class="img3" @click="go(2)">
        <div class="sceneBox">
          <div class="sceneIcon icon2"></div>
          <div class="sceneTitle">网格化管理</div>
          <div class="sceneContent">
            融合社区内部人、地、物、事、情、组织等信息于一体，以GIS可视化地图的形式将网格信息、人员、事件、部件等信息集成作统一的可视化呈现，减轻治理工作负担、提升治理工作效果。
          </div>
        </div>
      </div>
      <div class="img4" @click="go(3)">
        <div class="sceneBox">
          <div class="sceneIcon icon3"></div>
          <div class="sceneTitle">数据统一掌控</div>
          <div class="sceneContent">
            建立基础信息数据库，以此为基础进行扩容，融合各部门业务数据，进而形成大数据应用中心，从而实现数据统一掌控。
          </div>
        </div>
      </div>
      <div class="img5" @click="go(4)">
        <div class="sceneBox">
          <div class="sceneIcon icon4"></div>
          <div class="sceneTitle">安防管控</div>
          <div class="sceneContent">
            通过视频监控、物联网(消防数据、窨井盖数据、垃圾桶数据等）等系统对接初步实现初步的可视化指挥。
          </div>
        </div>
      </div>
      <div class="img6" @click="go(5)">
        <div class="sceneBox">
          <div class="sceneIcon icon5"></div>
          <div class="sceneTitle">视联网应用</div>
          <div class="sceneContent">
            平台依托了视联动力“高清、实时，大规模、双向、结构性安全”传输视频的视联网技术优势，及时有效发现基层治理难题，打通行业管理部门横向壁垒，提高基层综合治理效率，让基层治理工作简约、高效。
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  props: {},
  components: {},
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {
    handleScroll() {
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      console.log(scrollTop);
    },
    go(i) {
      if (i == 1) {
        this.$router.push("unified");
      }
      if (i == 2) {
        this.$router.push("grid");
      }
      if (i == 3) {
        this.$router.push("unifiedCommand");
      }
      if (i == 4) {
        this.$router.push("Security");
      }
      if (i == 5) {
        this.$router.push("visualNetworking");
      }
    },
  },
  created() {},
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
};
</script> 

<style scoped lang="scss">
.content-box {
  width: 100%;
  overflow: hidden;
  .title-box {
    width: 100%;
    color: #fff;
    height: 530px;
    padding: 0 160px;
    background: url("../../assets/image/theBasicLevel/bg1.png") no-repeat;
    background-size: contain;
    .title-name {
      font-size: 44px;
      font-weight: normal;
      color: #ffffff;
      transform: translateY(190px);
    }
    .title-p {
      width: 55%;
      font-size: 18px;
      font-weight: 300;
      line-height: 28px;
      transform: translateY(220px);
      color: #ffffff;
    }
  }
  .function {
    padding: 0 10%;
    .function-title {
      text-align: center;
      padding: 30px 0;
      font-size: 40px;
      font-weight: 600;
      color: #333333;
      margin-top: 270px;
    }
    .services {
      width: 100%;
      height: 700px;
      display: flex;
      background-color: #fff;
      .left {
        width: 100%;
        height: 920px;
        background: url("../../assets/image/theBasicLevel/组 291.png") no-repeat;
        background-size: 100% 100%;
      }
    }
  }
  .scene {
    margin: 0 10%;
    display: flex;
    margin-bottom: 50px;
    overflow: hidden;
    background-color: #333;

    > div {
      flex: 1;
      &:hover {
        transform: scale(1.07, 1.07);
      }
    }
    .sceneBox {
      cursor: pointer;
      &:hover {
        background: rgba(0, 0, 0, 0.7);
        opacity: 0.8;
        .sceneIcon {
          animation: moves 0.5s linear;
          animation-fill-mode: forwards;
        }
        .sceneTitle,
        .sceneContent {
          display: block;
        }
        @keyframes moves {
          0% {
          }
          100% {
            margin-top: 100px;
          }
        }
      }

      width: 100%;
      height: 500px;
      display: flex;
      flex-direction: column;
      align-items: center;
      // padding-top: 110px;
      box-sizing: border-box;

      .sceneIcon {
        margin-top: 180px;
        width: 44px;
        height: 44px;
        z-index: 99;
      }
      .sceneTitle {
        font-size: 20px;
        font-family: PingFang SC;
        font-weight: 600;
        color: #ffffff;
        margin: 5px 0;
        opacity: 1;
        z-index: 99;
        box-sizing: border-box;
        padding-bottom: 10px;
        border-bottom: 2px solid #fff;
      }
      .sceneContent {
        color: #fff;
        margin: 10px 40px;
        z-index: 99;
        display: none;
      }
    }
  }
  .img2 {
    background: url("../../assets/image/theBasicLevel/组 313.png") no-repeat;
    background-size: 100% 100%;
  }
  .img3 {
    background: url("../../assets/image/theBasicLevel/组 314.png") no-repeat;
    background-size: 100% 100%;
  }
  .img4 {
    background: url("../../assets/image/theBasicLevel/组 315.png") no-repeat;
    background-size: 100% 100%;
  }
  .img5 {
    background: url("../../assets/image/theBasicLevel/组 316.png") no-repeat;
    background-size: 100% 100%;
  }
  .img6 {
    background: url("../../assets/image/theBasicLevel/组 317.png") no-repeat;
    background-size: 100% 100%;
  }
  .icon1 {
    background: url("../../assets/image/theBasicLevel/icon1.png") no-repeat;
    background-size: 100% 100%;
  }
  .icon2 {
    background: url("../../assets/image/theBasicLevel/icon2.png") no-repeat;
    background-size: 100% 100%;
  }
  .icon3 {
    background: url("../../assets/image/theBasicLevel/icon3.png") no-repeat;
    background-size: 100% 100%;
  }
  .icon4 {
    background: url("../../assets/image/theBasicLevel/icon4.png") no-repeat;
    background-size: 100% 100%;
  }
  .icon5 {
    background: url("../../assets/image/theBasicLevel/组 196.png") no-repeat;
    background-size: 100% 100%;
  }
}
</style>